import { Component, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @Input() activeTabName: String;

  constructor(private router: Router) {}

  ngOnInit(): void {
  }

  ngOnChanges(activeTabName) {
    this.setActive(activeTabName, "");
  }

  currentChoice: string = "contacto";

  setActive(choice: string, route: string): void {
    let redirectionRoute = '';

    this.activeTabName = choice;

    switch (route) {
      case "inicio":
        redirectionRoute = '/inicio';
        break;

      case "quienessomos":
        redirectionRoute = '/quienessomos';
        break;

      case "servicios":
        redirectionRoute = '/servicios';
        break;

      case "formacion":
        redirectionRoute = '/formacion';
        break;

      case "contacto":
        redirectionRoute = '/contacto';
        break;

      default:
        redirectionRoute = '/inicio';
        break;
    }

    this.router.navigate([redirectionRoute]);
  }

}
