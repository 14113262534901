import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'azr-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @Input() activeTabName: String;

  @Output()
  changeActiveTab: EventEmitter<String> = new EventEmitter<String>();

  constructor() { }

  ngOnInit(): void {
  }


  changeActiveTabOnHeader(selectedTab: String) {
    this.activeTabName = selectedTab;
    this.changeActiveTab.emit(selectedTab);
  }

}
