import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './core/home/home.component'
import { ServicesComponent } from './core/services/services.component'
import { ContactComponent } from './core/contact/contact.component'
import { AboutComponent } from './core/about/about.component'
import { TrainingComponent } from './core/training/training.component'

const routes: Routes = [
  { path: 'inicio', component: HomeComponent},
  { path: 'servicios', component: ServicesComponent},
  { path: 'contacto', component: ContactComponent},
  { path: 'quienessomos', component: AboutComponent},
  { path: 'formacion', component: TrainingComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
