<!-- <div name="main-box">
    <div name="title" class="centered-box-title">
        <h1>{{'home.title' | translate}}</h1>
    </div>
    <div name="method" class="centered-box-methodology">
        <h1>{{'home.method' | translate}}</h1>
    </div>
    <div name="motivational_sentence" class="centered-box-subtitle">
        <img src="../../../assets/images/meth.png" alt="No se pudo cargar la imagen" width="70%">
    </div>
</div> -->

<div class="container">
    <div class="text">
        <h1>Directo a la nube</h1>
        <span class="subtext">
            ¡Únete al cambio y sube tu compañía a la nube con nosotros! Despliega el verdadero potencial
        </span>
    <br/>
        <button class="button" (click)="setActive('contact', 'contacto')">
            Empieza ya
        </button>
    </div>
    <div class="image">
        <img src="../../../assets/images/Uploading.svg">
    </div>
</div>

<div class="container space">
    <div class="image">
        <img src="../../../assets/images/Methods.svg">
    </div>
    <div class="text">
        <h1>Conoce el método</h1>
        <span class="subtext">
            Nuestra forma de trabajar se adapta a tus necesidades y siempre parte de un excelente estándar de calidad
        </span>
    </div>
</div>
<azr-footer></azr-footer>