import { CompileShallowModuleMetadata } from "@angular/compiler";
import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  @Input() activeTabName: String;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  ngOnChanges(activeTabName) {
    this.setActive(activeTabName, "");
  }

  currentChoice: string = "home";

  setActive(choice: string, route: string): void {
    let redirectionRoute = '';

    this.currentChoice = choice;
    this.activeTabName = choice;

    switch (route) {
      case "inicio":
        redirectionRoute = '/inicio';
        break;

      case "quienessomos":
        redirectionRoute = '/quienessomos';
        break;

      case "servicios":
        redirectionRoute = '/servicios';
        break;

      case "formacion":
        redirectionRoute = '/formacion';
        break;

      case "contacto":
        redirectionRoute = '/contacto';
        break;

      default:
        redirectionRoute = '/inicio';
        break;
    }

    this.router.navigate([redirectionRoute]);
  }

  getActive(choice: string): string {
    if (this.activeTabName == choice) return "active";
    else return "not";
  }
}
