<!-- <div class="main-container">
    <div class="container">
        <div class="section">
            <h4>{{ 'services.improvement' | translate }}</h4>
            <div class="description">
                <span>Mejora del código de tus aplicaciones, buscando un incremento en el rendimiento de las mismas</span>
            </div>
        </div>
        <div class="section">
            <h4>{{ 'services.projects' | translate }}</h4>
            <div class="description">
                <span>Proyectos a medida de actualización de OnPrem a Cloud</span>
            </div>
        </div>
        <div class="section">
            <h4>{{ 'services.consultory' | translate }}</h4>
            <div class="description">
                <span>Ayudamos a las empresas en su día a día, desde el correo a migraciones de aplicaciones corporativas. Soporte remoto. Servicios de instalación.</span>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="section">
            <h4>{{ 'services.support' | translate }}</h4>
            <div class="description">
                <span>Texto de ejemplo.</span>
            </div>
        </div>
        <div class="section">
            <h4>{{ 'services.digital_transformation' | translate }}</h4>
            <div class="description">
                <span>Texto de ejemplo.</span>
            </div>
        </div>
        <div class="section">
            <h4>{{ 'services.architecture' | translate }}</h4>
            <div class="description">
                <span>Texto de ejemplo.</span>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="section">
            <h4>{{ 'services.cloud_solutions' | translate }}</h4>
        </div>
        <div class="section">
            <h4>{{ 'services.function_on_demand' | translate }}</h4>
        </div>
        <div class="section">
            <h4>{{ 'services.development' | translate }}</h4>
            <div class="description">
                <span>Soluciones a medida o adaptación de sistemas existentes. PHP, Java, JS, Python, Android e iOS</span>
            </div>
        </div>
    </div>
</div> -->
<!--<div class='container-fluid mx-auto mt-5 mb-5 col-12 centered-text'>
    <div class="hd">
        {{ 'services.title' | translate }}
    </div>
    <p>
        <small class="text-muted">{{ 'services.description' | translate }}</small>
    </p>
    <div class="row justified">
        <div class="card col-md-3 col-12" id="improvement-card">
            <div class="card-content">
                <div class="card-body"> <i class="fas fa-chart-line fa-5x orange"></i>
                    <div class="shadow"></div>
                    <div class="card-title"> {{ 'services.improvement' | translate }} </div>
                    <div class="card-subtitle">
                        <p>
                            <small class="text-muted">
                                {{ 'services.improvement_desc' | translate }}
                            </small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card col-md-3 col-12 ml-3 marginleft" id="projects-card">
            <div class="card-content">
                <div class="card-body"> <i class="fas fa-cloud-upload-alt fa-5x orange"></i>
                    <div class="shadow"></div>
                    <div class="card-title"> {{ 'services.projects' | translate }} </div>
                    <div class="card-subtitle">
                        <p>
                            <small class="text-muted">
                                {{ 'services.projects_desc' | translate }}
                            </small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card col-md-3 col-12 ml-3" id="consultory-card">
            <div class="card-content">
                <div class="card-body"> <i class="fas fa-chalkboard-teacher fa-5x orange"></i>
                    <div class="shadow"></div>
                    <div class="card-title"> {{ 'services.consultory' | translate }} </div>
                    <div class="card-subtitle">
                        <p>
                            <small class="text-muted">
                                {{ 'services.consultory_desc' | translate }}
                            </small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row justified mt-3">
        <div class="card col-md-3 col-12" id="support-card">
            <div class="card-content">
                <div class="card-body"> <i class="fas fa-headset fa-5x orange"></i>
                    <div class="shadow"></div>
                    <div class="card-title"> {{ 'services.support' | translate }} </div>
                    <div class="card-subtitle">
                        <p>
                            <small class="text-muted">
                                {{ 'services.support_desc' | translate }}
                            </small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card col-md-3 col-12 ml-3" id="digital_transformation-card">
            <div class="card-content">
                <div class="card-body"> <i class="fas fa-laptop-code fa-5x orange"></i>
                    <div class="shadow"></div>
                    <div class="card-title"> {{ 'services.digital_transformation' | translate }} </div>
                    <div class="card-subtitle">
                        <p>
                            <small class="text-muted">
                                {{ 'services.digital_transformation_desc' | translate }}
                            </small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card col-md-3 col-12 ml-3" id="architecture-card">
            <div class="card-content">
                <div class="card-body"> <i class="fas fa-sitemap fa-5x orange"></i>
                    <div class="shadow"></div>
                    <div class="card-title"> {{ 'services.architecture' | translate }} </div>
                    <div class="card-subtitle">
                        <p>
                            <small class="text-muted">
                                {{ 'services.architecture_desc' | translate }}
                            </small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row justified mt-3">
        <div class="card col-md-3 col-12" id="cloud_solutions-card">
            <div class="card-content">
                <div class="card-body"> <i class="fas fa-cloud fa-5x orange"></i>
                    <div class="shadow"></div>
                    <div class="card-title"> {{ 'services.talent_hunt' | translate }} </div>
                    <div class="card-subtitle">
                        <p> 
                            <small class="text-muted">
                                {{ 'services.talent_hunt_desc' | translate }}
                            </small> 
                        </p>
                    </div>
                </div>
            </div>
        </div> 
        <div class="card col-md-3 col-12 ml-3" id="function_on_demand-card">
            <div class="card-content">
                <div class="card-body"> <i class="fas fa-user-check fa-5x orange"></i>
                    <div class="shadow"></div>
                    <div class="card-title"> {{ 'services.function_on_demand' | translate }} </div>
                    <div class="card-subtitle">
                        <p>
                            <small class="text-muted">
                                {{ 'services.function_on_demand_desc' | translate }}
                            </small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card col-md-3 col-12 ml-3" id="development-card">
            <div class="card-content">
                <div class="card-body"> <i class="fas fa-code fa-5x orange"></i>
                    <div class="shadow"></div>
                    <div class="card-title"> {{ 'services.security' | translate }} </div>
                    <div class="card-subtitle">
                        <p> 
                            <small class="text-muted">
                                {{ 'services.security_desc' | translate }}
                            </small> 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="main">
    <div class="title">
        <h1>{{ 'services.title' | translate }}</h1>
    </div>
    <div class="subtitle">
        <span>{{ 'services.description' | translate }}</span>
    </div>
    <ul class="cards">
      <li class="cards_item">
        <div class="card">
          <div class="card_image"><img src="../../../assets/images/OnPremToCloud.svg"></div>
          <div class="card_content">
            <h2 class="card_title">{{ 'services.projects' | translate }}</h2>
            <p class="card_text">{{ 'services.projects_desc' | translate }}</p>
          </div>
        </div>
      </li>
      <li class="cards_item">
        <div class="card">
          <div class="card_image"><img src="../../../assets/images/Consultoria.svg"></div>
          <div class="card_content">
            <h2 class="card_title">{{ 'services.consultory' | translate }}</h2>
            <p class="card_text">{{ 'services.consultory_desc' | translate }}</p>
          </div>
        </div>
      </li>
      <li class="cards_item">
        <div class="card">
          <div class="card_image"><img src="../../../assets/images/Arquitectura.svg"></div>
          <div class="card_content">
            <h2 class="card_title">{{ 'services.architecture' | translate }}</h2>
            <p class="card_text">{{ 'services.architecture_desc' | translate }}</p>
          </div>
        </div>
      </li>
      <li class="cards_item">
        <div class="card">
          <div class="card_image"><img src="../../../assets/images/PostImplantaction.svg"></div>
          <div class="card_content">
            <h2 class="card_title">{{ 'services.support' | translate }}</h2>
            <p class="card_text">{{ 'services.support_desc' | translate }}</p>
          </div>
        </div>
      </li>
      <li class="cards_item">
        <div class="card">
          <div class="card_image"><img src="../../../assets/images/TransDigital.svg"></div>
          <div class="card_content">
            <h2 class="card_title">{{ 'services.digital_transformation' | translate }}</h2>
            <p class="card_text">{{ 'services.digital_transformation_desc' | translate }}</p>
          </div>
        </div>
      </li>
      <li class="cards_item">
        <div class="card">
          <div class="card_image"><img src="../../../assets/images/Auditoria.svg"></div>
          <div class="card_content">
            <h2 class="card_title">{{ 'services.function_on_demand' | translate }}</h2>
            <p class="card_text">{{ 'services.function_on_demand_desc' | translate }}</p>
          </div>
        </div>
      </li>
      <li class="cards_item">
        <div class="card">
          <div class="card_image"><img src="../../../assets/images/Performance.svg"></div>
          <div class="card_content">
            <h2 class="card_title">{{ 'services.improvement' | translate }}</h2>
            <p class="card_text">{{ 'services.improvement_desc' | translate }}</p>
          </div>
        </div>
      </li>
      <li class="cards_item">
        <div class="card">
          <div class="card_image"><img src="../../../assets/images/Security.svg"></div>
          <div class="card_content">
            <h2 class="card_title">{{ 'services.security' | translate }}</h2>
            <p class="card_text">{{ 'services.security_desc' | translate }}</p>
          </div>
        </div>
      </li>
      <li class="cards_item">
        <div class="card">
          <div class="card_image"><img src="../../../assets/images/TalentHunt.svg"></div>
          <div class="card_content">
            <h2 class="card_title">{{ 'services.talent_hunt' | translate }}</h2>
            <p class="card_text">{{ 'services.talent_hunt_desc' | translate }}</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
<azr-footer></azr-footer>