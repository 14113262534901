<!-- <div class="main-container">
    <div class="grid">
        <div id="item-1" class="mt-5">
            <div>
                <i class="fas fa-envelope fa-5x orange"></i>
                <div class="contact-data-mail">
                    {{ 'contact.email' | translate }}
                </div>
            </div>
            <div class="mt-3">
                <i class="fas fa-phone fa-5x orange"></i>
                <div class="contact-data-phone">
                    {{ 'contact.email' | translate }}
                </div>
            </div>
        </div>
        <div id="item-2">
            <div>
                <h3>Contacta con nosotros</h3>
            </div>
            <form class="mt-4" action="mailto:someone@example.com" method="post" enctype="text/plain">
                <div class="form-group">
                    <input type="text" name="txtName" class="form-control" placeholder="Nombre*" value="" />
                </div>
                <div class="form-group">
                    <input type="text" name="txtEmail" class="form-control" placeholder="Email*" value="" />
                </div>
                <div class="form-group">
                    <input type="text" name="txtPhone" class="form-control" placeholder="Teléfono" value="" />
                </div>
                <div>
                    <div class="form-group">
                        <textarea name="txtMsg" class="form-control" placeholder="Escribe aquí tu mensaje*" style="width: 100%; height: 150px;"></textarea>
                    </div>
                </div>
                <div class="form-group">
                    <input type="submit" name="btnSubmit" class="btnContactSubmit" value="Enviar" />
                </div>
            </form>
        </div>
    </div>
</div>
<div style="margin-bottom: 5%;">
</div> -->

<!--Section: Contact v.2-->
<div class="mContainer">
    <section class="mb-4 ml-2 mt-3">
        <h2 class="h1-responsive text-center my-4">{{ 'contact.title' | translate }}</h2>
        <p class="text-center w-responsive mx-auto mb-5">{{ 'contact.subtitle' | translate }}</p>
        <div class="row" style="margin: auto;">
            <div class="col-md-9 mb-md-0 mb-5">
                <form id="contact-form" name="contact-form" enctype="text/plain" method="post" action="mailto: contacto@azr-cloud.es">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="md-form mb-0">
                                <input type="text" id="name" name="name" class="form-control">
                                <label for="name" class="">{{ 'contact.name' | translate }}</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="md-form mb-0">
                                <input type="text" id="email" name="email" class="form-control">
                                <label for="email" class="">{{ 'contact.your_email' | translate }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="md-form mb-0">
                                <input type="text" id="subject" name="subject" class="form-control">
                                <label for="subject" class="">{{ 'contact.subject' | translate }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="md-form">
                                <textarea type="text" id="message" name="message" rows="2" class="form-control md-textarea"></textarea>
                                <label for="message">{{ 'contact.message' | translate }}</label>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="text-center text-md-left">
                    <input type="submit" name="btnSubmit" class="btnContactSubmit" value="Enviar" />
                </div>
                <div class="status"></div>
            </div>
            <div class="col-md-3 text-center">
                <ul class="list-unstyled mb-0">
                    <!--<li><i class="fas fa-map-marker-alt fa-2x lightblue"></i>
                        <p>San Francisco, CA 94126, USA</p>
                    </li>-->
                    <li><i class="fas fa-phone mt-4 fa-2x lightblue"></i>
                        <p>{{ 'contact.phone' | translate }}</p>
                    </li>
                    <li><i class="fas fa-envelope mt-4 fa-2x lightblue"></i>
                        <p>{{ 'contact.email' | translate }}</p>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</div>
<!--Section: Contact v.2-->
<azr-footer></azr-footer>