import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'azr-cloud';
  public changeTabEvt: Event;
  public activeTabName: String;

  constructor(private translate: TranslateService, private router: Router) {
    translate.setDefaultLang('es');
  }

  ngOnInit() {
    this.router.navigate(['']);
  }


  changeActiveTab(event: Event) {
    this.changeTabEvt = event;
  }

}
