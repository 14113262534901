<footer id="myFooter">
    <div class="container">
        <div class="row">
            <div class="col-sm-3 logo">
                <img src="../../../assets/images/azr-cloud-logo.png" alt="No se pudo cargar la imagen" width="45%">
            </div>
            <div class="col-sm-2">
                <h5>Comienza</h5>
                <ul>
                    <li><a (click)="changeActiveTabOnHeader('home')" routerLink="/inicio">{{ 'header.home' | translate }}</a></li>
                    <li><a (click)="changeActiveTabOnHeader('services')" routerLink="/servicios">{{ 'header.services' | translate }}</a></li>
                </ul>
            </div>
            <div class="col-sm-2">
                <h5>{{ 'footer.about' | translate }}</h5>
                <ul>
                    <li><a (click)="changeActiveTabOnHeader('about')" routerLink="/quienessomos">{{ 'about.title' | translate }}</a></li>
                    <li><a (click)="changeActiveTabOnHeader('contact')" routerLink="/contacto">{{ 'footer.contact' | translate }}</a></li>
                </ul>
            </div>
            <div class="col-sm-2">
                <h5>{{ 'header.training' | translate }}</h5>
                <ul>
                    <li><a (click)="changeActiveTabOnHeader('training')" routerLink="/formacion">{{ 'footer.training' | translate }}</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="footer-copyright">
        <p>{{ 'footer.copyright' | translate }}</p>
    </div>
</footer>