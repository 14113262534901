<div class="center mb-2">
    <div>
        <div class="center-text">
            <h3>{{'header.training' | translate}}</h3>
            <div>
                {{'training.description' | translate}}
            </div>
        </div>
    </div>
    <div class="mt-3">
        <div class="row justified main-container">
            <div class="card col-md-3 col-12">
                <div class="card-content">
                    <div class="card-body"> <i class="fas fa-chalkboard-teacher fa-5x orange"></i>
                        <div class="card-title"> {{ 'training.service_1' | translate }} </div>
                    </div>
                </div>
            </div>
            <div class="card col-md-3 col-12">
                <div class="card-content">
                    <div class="card-body"> <i class="fas fa-people-arrows fa-5x orange"></i>
                        <div class="card-title"> {{ 'training.service_2' | translate }} </div>
                    </div>
                </div>
            </div>
            <div class="card col-md-3 col-12">
                <div class="card-content">
                    <div class="card-body"> <i class="fas fa-check fa-5x orange"></i>
                        <div class="card-title"> {{ 'training.service_3' | translate }} </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="center-text">
        <h3>{{'training.header_secondary' | translate}}</h3>
        <div>
            {{'training.additional_one' | translate}}
        </div>
        <br/>
        <div>
            {{'training.additional_two' | translate}}
        </div>
        <br/>
        <div>
            {{'training.additional_three' | translate}}
        </div>
        <br/>
        <div>
            {{'training.additional_four' | translate}}
        </div>
    </div>
</div>

<azr-footer></azr-footer>