<div class="center">
    <div>
        <div class="center-text">
            <h3>{{'about.title' | translate}}</h3>
            <div>
                {{'about.title_description' | translate}}
            </div>
        </div>
    </div>
</div>
<div class="center">
    <div>
        <div class="center-text">
            <h3>{{'about.title_second' | translate}}</h3>
            <div>
                {{'about.title_second_description' | translate}}
            </div>
        </div>
    </div>
</div>
<div class="center">
    <div>
        <div class="center-text">
            <h3>{{'about.title_third' | translate}}</h3>
            <div>
                {{'about.title_third_description' | translate}}
            </div>
            <br/>
            <div>
                {{'about.title_third_one_description' | translate}}
            </div>
            <br/>
            <div>
                {{'about.title_third_two_description' | translate}}
            </div>
        </div>
    </div>
</div>

<azr-footer></azr-footer>