<!-- <nav class="navbar navbar-expand-md navbar-light bg-none grey-bg">
    <a class="navbar-brand">
        <img src="../../../assets/images/azr-cloud-logo.png" alt="No se pudo cargar la imagen" width="35%" height="35%">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse align-items-md-center" id="navbarNavDropdown">
        <ul class="navbar-nav">
            <li class="nav-item {{getActive('home')}}" (click)="setActive('home', 'inicio')" name="home">
                <a class="nav-link">{{'header.home' | translate}}</a>
            </li>
            <li class="nav-item {{getActive('about')}}" (click)="setActive('about', 'quienessomos')" name="about">
                <a class="nav-link">{{'header.about' | translate}}</a>
            </li>
            <li class="nav-item {{getActive('services')}}" (click)="setActive('services', 'servicios')" name="services">
                <a class="nav-link">{{'header.services' | translate}}</a>
            </li>
            <li class="nav-item {{getActive('trainig')}}" (click)="setActive('trainig', 'formacion')" name="trainig">
                <a class="nav-link">{{'header.training' | translate}}</a>
            </li>
            <li class="nav-item {{getActive('contact')}}" (click)="setActive('contact', 'contacto')" name="contact">
                <a class="nav-link">{{'header.contact' | translate}}</a>
            </li>
        </ul>
    </div>
</nav> -->
<nav class="navbar navbar-expand-lg navbar-light" style="background-color:#ffffff;">
    <div class="container-fluid">
        <a class="navbar-brand">
            <img src="../../../assets/images/azr-cloud-logo.png" alt="No se pudo cargar la imagen" width="70px">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item {{getActive('home')}}" (click)="setActive('home', 'inicio')" name="home">
                    <a class="nav-link">{{'header.home' | translate}}</a>
                </li>
                <li class="nav-item {{getActive('about')}}" (click)="setActive('about', 'quienessomos')" name="about">
                    <a class="nav-link">{{'header.about' | translate}}</a>
                </li>
                <li class="nav-item {{getActive('services')}}" (click)="setActive('services', 'servicios')" name="services">
                    <a class="nav-link">{{'header.services' | translate}}</a>
                </li>
                <li class="nav-item {{getActive('trainig')}}" (click)="setActive('trainig', 'formacion')" name="trainig">
                    <a class="nav-link">{{'header.training' | translate}}</a>
                </li>
            </ul>
            <a (click)="setActive('contact', 'contacto')" name="contact" class="btn headerBtn">{{'header.contact' | translate}}</a>
        </div>
    </div>
</nav>